import {
  GetBankNameMappingDataApiResponse,
  TokenTransaction,
  LegalEntityNameMapping,
  TokenBalance,
  TransactionType,
  GetOwnTransactionHistoryApiResponse,
  GetTbdBalancesApiResponse,
} from "../store/bankApi";
import AlertMsg from "../components/Alert";
import { ownUtxoAccount } from "../components/Environment";
import dayjs, { Dayjs } from "dayjs";

let localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export type FormattedTransaction = TokenTransaction & {
  accountNoFromUserFriendlyName?: string;
  accountNoToUserFriendlyName?: string;
  operationUserFriendlyName?: string;
  counterparty?: string;
};

/**
 * Map strings from API type -> display string.
 * The l.h.s. keys are strongly typed via the generated TransactionType.
 */
export const getDisplayString: { [key in TransactionType]: string } = {
  TransferwNOK: "Transfer",
  Escrow: "Escrow",
  ReleaseEscrow: "Release Escrow",
  Mint: "Mint",
  Burn: "Burn",
  CreateAccount: "Create Account",
};

/**
 * Check membership of txo in the array tts,
 * where the elements of tts are statically checked.
 */
export const isAmongTransactionTypes = (
  tts: TransactionType[],
  txo: TransactionType,
): boolean => {
  return tts.includes(txo);
};

export function transformAccount(
  account: TokenBalance | undefined,
  bankNameMapping: GetBankNameMappingDataApiResponse | undefined,
): TokenBalance | undefined {
  if (!account || !bankNameMapping) return account;

  const bankNameMap: { [key: string]: string } = {};
  bankNameMapping.forEach((bankName: LegalEntityNameMapping) => {
    bankNameMap[bankName.utxoName!] = bankName.legalName!;
  });

  return {
    ...account,
    accountNo: bankNameMap[account.accountNo!] || account.accountNo,
  };
}

export function transformAccountTransactions(
  transactions: GetOwnTransactionHistoryApiResponse,
  bankNameMapping: GetBankNameMappingDataApiResponse,
): FormattedTransaction[] {
  // Map utxo name to display name (legal name)
  const bankNameMap: { [key: string]: string } = {};
  bankNameMapping.forEach((bankName: LegalEntityNameMapping) => {
    bankNameMap[bankName.utxoName!] = bankName.legalName!;
  });

  // Determine the counter party for the given transaction
  function getCounterparty(transaction: TokenTransaction): string {
    if (transaction.accountNoFrom === ownUtxoAccount) {
      return bankNameMap[transaction.accountNoTo!] || transaction.accountNoTo!;
    }
    if (transaction.accountNoTo === ownUtxoAccount) {
      return (
        bankNameMap[transaction.accountNoFrom!] || transaction.accountNoFrom!
      );
    }
    return "--";
  }

  return transactions.map((transaction) => {
    return {
      ...transaction,
      accountNoFromUserFriendlyName:
        bankNameMap[transaction.accountNoFrom!] || transaction.accountNoFrom!,
      accountNoToUserFriendlyName:
        bankNameMap[transaction.accountNoTo!] || transaction.accountNoTo!,
      operationUserFriendlyName:
        getDisplayString[transaction.operation!] ||
        (transaction.operation as string),
      counterparty: getCounterparty(transaction),
    };
  });
}

export type AccountBalanceAdditions = {
  legalName: string | undefined;
  tbdBalance: number | undefined;
};

export type TransformedAccountBalance = TokenBalance & AccountBalanceAdditions;

export function transformAccountBalances(
  balances: TokenBalance[] | undefined,
  bankNameMapping: GetBankNameMappingDataApiResponse | undefined,
  tbdBalances: GetTbdBalancesApiResponse | undefined,
): (TokenBalance & AccountBalanceAdditions)[] | undefined {
  if (!balances || !bankNameMapping)
    return balances as TransformedAccountBalance[];
  // if (!tbdBalances || !bankNameMapping) return balances;

  // Note: balance.accountNo is a UTXO name ("BankB")

  // Map UTXO name ("BankB") to legal name ("Oslo Bank")
  const utxoToLegalNameMap: { [key: string]: string } = {};
  bankNameMapping.forEach((bankName: LegalEntityNameMapping) => {
    utxoToLegalNameMap[bankName.utxoName!] = bankName.legalName!;
  });

  // Map Fabric name ("Org2MSP") to legal name ("Oslo Bank")
  const legalToUtxoNameMap: { [key: string]: string } = {};
  bankNameMapping.forEach((bankName: LegalEntityNameMapping) => {
    legalToUtxoNameMap[bankName.legalName!] = bankName.utxoName!;
  });

  // Map UTXO name to TBD balance
  const tbdBalanceMap: { [key: string]: number } = {};
  tbdBalances?.forEach((tbdBalance: TokenBalance) => {
    tbdBalanceMap[legalToUtxoNameMap[tbdBalance.accountNo!]] =
      tbdBalance.amount;
  });

  return balances.map((balance) => ({
    ...balance,
    legalName: utxoToLegalNameMap[balance.accountNo!] || balance.accountNo,
    tbdBalance: tbdBalanceMap[balance.accountNo!] || undefined,
  }));
}

export function createAlerts(
  alertMsgs: string[],
  severities: ("error" | "warning" | "info" | "success")[],
) {
  let alerts = [];
  for (let i = 0; i < alertMsgs.length; i++) {
    alerts.push(
      <AlertMsg message={alertMsgs[i]} severity={severities[i]} alertkey={i} />,
    );
  }
  return alerts;
}

export function getTimeStepForSmoothing(tDiff: number) {
  let tStep = 1000; // 1 second

  if (tDiff > 30 * 1000 && tDiff <= 5 * 60 * 1000) {
    tStep = 10 * 1000; // 10 seconds
  } else if (tDiff <= 15 * 60 * 1000) {
    tStep = 30 * 1000; // 30 seconds
  } else if (tDiff <= 30 * 60 * 1000) {
    tStep = 1 * 60 * 1000; // 1 minute
  } else if (tDiff <= 150 * 60 * 1000) {
    tStep = 5 * 60 * 1000; // 5 minutes
  } else if (tDiff <= 5 * 60 * 60 * 1000) {
    tStep = 10 * 60 * 1000; // 10 minutes
  } else if (tDiff <= 15 * 60 * 60 * 1000) {
    tStep = 30 * 60 * 1000; // 30 minutes
  } else if (tDiff <= 2 * 24 * 60 * 60 * 1000) {
    tStep = 1 * 60 * 60 * 1000; // 1 hour
  } else if (tDiff <= 7 * 24 * 60 * 60 * 1000) {
    tStep = 4 * 60 * 60 * 1000; // 4 hours
  } else if (tDiff <= 31 * 24 * 60 * 60 * 1000) {
    tStep = 24 * 60 * 60 * 1000; // 1 day
  } else if (tDiff <= 26 * 7 * 24 * 60 * 60 * 1000) {
    tStep = 7 * 24 * 60 * 60 * 1000; // 7 days
  } else if (tDiff <= 2 * 365 * 24 * 60 * 60 * 1000) {
    tStep = 30 * 24 * 60 * 60 * 1000; // 30 days
  } else if (tDiff <= 10 * 365 * 24 * 60 * 60 * 1000) {
    tStep = 3 * 30 * 24 * 60 * 60 * 1000; // 3 months
  } else if (tDiff <= 30 * 365 * 24 * 60 * 60 * 1000) {
    tStep = 365 * 24 * 60 * 60 * 1000; // 1 year
  } else {
    tStep = 10 * 365 * 24 * 60 * 60 * 1000; // 10 years
  }

  return tStep;
}

export function getSmoothedTimeSeries(
  data: Array<any>,
  tFrom: Dayjs,
  tTo: Dayjs,
  amountAtTTo: number,
) {
  let times = data.map((datum) => dayjs(datum.timestamp).local()).reverse();
  let amounts = data.map((datum) => datum.amount).reverse();

  let tDiff = tTo.diff(tFrom);

  const tStep = getTimeStepForSmoothing(tDiff);

  let aggregatedTimes = [];
  let aggregatedAmounts = [];

  let t = tFrom;
  let i = 0;

  let aggregatedAmount;
  if (amounts.length === 0) {
    aggregatedAmount = amountAtTTo;
  } else {
    aggregatedAmount = amounts[0];
  }

  while (t.isBefore(tTo)) {
    while (i < times.length && times[i].isBefore(t.add(tStep))) {
      aggregatedAmount = amounts[i];
      i += 1;
    }

    aggregatedAmounts.push(aggregatedAmount);
    aggregatedTimes.push(t.toDate());
    t = t.add(tStep);
  }

  return { times: aggregatedTimes, amounts: aggregatedAmounts };
}

export function getAggregatedTimeSeries(
  data: Array<any>,
  tFrom: Dayjs,
  tTo: Dayjs,
) {
  let times = data.map((datum) => dayjs(datum.timestamp).local()).reverse();
  let amounts = data.map((datum) => datum.amount).reverse();

  let tDiff = tTo.diff(tFrom);

  const tStep = getTimeStepForSmoothing(tDiff);

  let aggregatedTimes = [];
  let aggregatedAmounts = [];

  let t = tFrom;
  let i = 0;

  while (t.isBefore(tTo)) {
    let aggregatedAmount = 0;
    while (i < times.length && times[i].isBefore(t.add(tStep))) {
      aggregatedAmount += amounts[i];
      i += 1;
    }

    aggregatedAmounts.push(aggregatedAmount);
    aggregatedTimes.push(t.toDate());
    t = t.add(tStep);
  }

  return { times: aggregatedTimes, amounts: aggregatedAmounts };
}
