import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  useGetBalancesForAllParticipantsInNetworkQuery,
  useGetBankNameMappingDataQuery,
  useGetTbdBalancesQuery,
} from "../store/bankApi";
import { transformAccountBalances } from "../utils/helpers";

export const AllAccountBalances = () => {
  const { data: accountBalances } = useGetBalancesForAllParticipantsInNetworkQuery(undefined, {
    pollingInterval: 7000,
  });
  const { data: bankNameMapping } = useGetBankNameMappingDataQuery();
  const { data: tbdBalances } = useGetTbdBalancesQuery();

  const transformedBalances = transformAccountBalances(
    accountBalances,
    bankNameMapping,
    tbdBalances,
  );
  if (!transformedBalances) return <>Loading</>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{
        minWidth: 200,
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none"
        }
      }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ borderBottom: 1 }}>
            <TableCell>Participant</TableCell>
            <TableCell align="right">Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ border: 0 }}>
          {transformedBalances.map((participant) => (
            <>
              <TableRow
                key={participant.legalName}
                sx={{ "&:last-child td": { paddingBottom: 2 } }}
              >
                <TableCell component="th" scope="row" sx={{ borderTop: 1, paddingTop: 1, paddingBottom: participant.tbdBalance ? 0 : 1 }}>
                  {participant.legalName}
                </TableCell>
                <TableCell align="right" sx={{ borderTop: 1, paddingTop: 1,paddingBottom: participant.tbdBalance ? 0 : 1 }}>{participant.amount}</TableCell>
              </TableRow>
              {participant.tbdBalance ? (
                <TableRow
                  key={participant.legalName + "-tbd"}
                  sx={{ border: 0 }}
                >
                  <TableCell align="left" sx={{ fontSize: 10, color: "grey", paddingTop: 0, borderTop: 0, paddingBottom: 1 }}>Tokenized bank deposits</TableCell>

                  <TableCell align="right" sx={{ fontSize: 10, color: "grey", paddingTop: 0, borderTop: 0, paddingBottom: 1 }}>{participant.tbdBalance}</TableCell>
                </TableRow>
              ) : undefined}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
