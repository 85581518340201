import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  LegalEntity,
  useGetTotalWnokInNetworkQuery,
  useGetWholesaleBankDataQuery,
} from "../store/bankApi.gen";

export default function BasicTable() {
  const { data: amount } = useGetTotalWnokInNetworkQuery(undefined, {
    pollingInterval: 7000,
  });
  const { data: banks } = useGetWholesaleBankDataQuery();

  if (!banks) return <>Loading</>;

  const allNames = banks.map(
    (bank: LegalEntity) => bank.legalName,
  );

  const numberOfBanks = allNames.length;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell align="right">Statistics</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            key={amount}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              Total wNOK
            </TableCell>
            <TableCell align="right">{amount ?? "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Number of Banks
            </TableCell>
            <TableCell align="right">{numberOfBanks}</TableCell>
          </TableRow>
          {/* End of Mock Row */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
