import React, { useState } from "react";
import Select, { SingleValue } from "react-select";
import { useGetOwnWholesaleBankDataQuery } from "../store/bankApi.gen";
import { networkProperties } from "../utils/networkProperties";

interface NamesListProps {
  onSelect: (remoteRecipientAndNetwork: {
    recipient: string;
    network: string;
  }) => void;
  placeholder: string;
}

type OptionType = {
  recipient: string;
  network: string;
  label: string;
};

const DropdownMenu: React.FC<NamesListProps> = ({ onSelect, placeholder }) => {
  const [selectedOption, setSelectedOption] =
    useState<SingleValue<OptionType>>(null);

  const { data: bankData, isLoading } =
    useGetOwnWholesaleBankDataQuery();

  if (isLoading || !bankData) return <>Loading...</>;

  const options: OptionType[] = bankData.flatMap((bank: any) =>
      networkProperties.map((network) => ({
        recipient: bank.besuAddress,
        network: network.networkId,
        label: `${bank.legalName} @ ${network.label}`,
        value: `${bank.besuAddress}_${network.networkId}`,
      }))
  );

  const handleChange = (option: SingleValue<OptionType>) => {
    setSelectedOption(option);
    onSelect({
      recipient: option?.recipient ?? "",
      network: option?.network ?? "",
    });
  };

    return (
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        styles={{
          container: (provided, state) => ({
            ...provided,
            width: 280,
            fontFamily: "Open Sans, sans-serif",
          }),
        }}
        placeholder={placeholder}
      />
    );
  };

export default DropdownMenu;
