import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  useGetOwnBalanceQuery,
  useGetBankNameMappingDataQuery,
} from "../store/bankApi";
import { transformAccount } from "../utils/helpers";

export default function BasicTable() {
  const { data: ownBalance } = useGetOwnBalanceQuery(undefined, {
    pollingInterval: 7000,
  });
  const { data: bankNameMapping } = useGetBankNameMappingDataQuery();

  if (!ownBalance || !bankNameMapping) return <>Loading</>;

  const transformedAccount = transformAccount(ownBalance, bankNameMapping);
  if (!transformedAccount) return <>Loading</>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Account Number</TableCell>
            <TableCell align="right">Balance wNOK</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>{transformedAccount.accountNo}</TableCell>
            <TableCell align="right">{transformedAccount.amount}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
