import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarContainerProps,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { ButtonProps } from "@mui/material/Button";
import dayjs from "dayjs";
import { GetBankNameMappingDataApiResponse } from "../store/bankApi";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

const csvOptions: GridCsvExportOptions = {
  delimiter: ";",
  fileName: "documents_list",
};

function DocumentExportButton(props: ButtonProps) {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
}

function CustomToolbar(props: GridToolbarContainerProps) {
  return (
    <GridToolbarContainer {...props}>
      <DocumentExportButton />
    </GridToolbarContainer>
  );
}

function displayMetricPrefix(bytes: number): string {
  if (bytes >= 100000) {
    return (bytes / 1000000).toFixed(1) + " MB";
  } else if (bytes >= 100) {
    return (bytes / 1000).toFixed(1) + " KB";
  } else {
    return bytes.toString() + " B";
  }
}

export default function BasicTable(
  documents: Array<any> | undefined,
  documentGridApiRef: React.MutableRefObject<any>,
  bankNameMapping: GetBankNameMappingDataApiResponse | undefined,
) {
  const emptyTableMessage = !documents
    ? "Please select an account and  a time range, then click on Update."
    : "No documents found in the given time range.";

  const nameMap: { [key: string]: string } = {};
  if (bankNameMapping) {
    bankNameMapping.forEach((bank) => {
      nameMap[bank.fabricName!] = bank.legalName!;
    });
  }

  const columns: GridColDef[] = [
    { field: "date", headerName: "Date", width: 150 },
    { field: "issuerUserFriendlyName", headerName: "Issuer", width: 150 },
    { field: "documentName", headerName: "Document name", width: 325 },
    {
      field: "documentSize",
      headerName: "File size",
      width: 75,
      align: "right",
    },
  ];

  const rows = !documents
    ? []
    : documents!.map((document) => ({
        id: document.documentId,
        date: dayjs(document.timestamp).local().format("L LT"),
        issuer: document.issuer,
        issuerUserFriendlyName: nameMap[document.issuer] || document.issuer,
        sharedWith: document.sharedWith,
        documentName: document.documentName,
        documentSize: displayMetricPrefix(document.documentSize),
      }));

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {emptyTableMessage}
            </Stack>
          ),
        }}
        apiRef={documentGridApiRef}
      />
    </Box>
  );
}
