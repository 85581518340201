import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

import localizedFormat from "dayjs/plugin/localizedFormat";
import {
  TokenTransaction,
  useGetWholesaleBankDataQuery,
} from "../store/bankApi.gen";
import { transformAccountTransactions } from "../utils/helpers";
import {
  EMPTY_VALUE,
  formattedAmount,
  formattedOperation,
} from "../services/TransactionTableFormat";

dayjs.extend(localizedFormat);

export default function BasicTable(
  transactions: TokenTransaction[] | undefined,
) {

  const [open, setOpen] = useState(false);
  const [currentSettlementId, setCurrentSettlementId] = useState("");
  const [currentTxId, setCurrentTxId] = useState("");

  const handleClickOpen = (settlementId: string, txId: string) => {
    setCurrentSettlementId(settlementId);
    setCurrentTxId(txId)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { data: bankData } = useGetWholesaleBankDataQuery();
  if (!transactions) return <>Loading</>;
  if (!bankData) return <>Loading</>;

  const formattedTransactions = transformAccountTransactions(
    transactions,
    bankData,
  );
  if (!formattedTransactions) return <>Loading</>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Operation</TableCell>
            <TableCell>Counterparty</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Balance</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formattedTransactions.length === 0 ? (
            <TableRow>
              {" "}
              No transactions found in the given time range.{" "}
            </TableRow>
          ) : (
            formattedTransactions.map((transaction) => (
              <TableRow
                key={transaction.timestamp}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {dayjs(transaction.timestamp).local().format("L LT")}
                </TableCell>
                <TableCell>{formattedOperation(transaction)}</TableCell>
                <TableCell>{transaction.counterparty || EMPTY_VALUE}</TableCell>
                <TableCell>{formattedAmount(transaction)}</TableCell>
                <TableCell>{transaction.ownBalance ?? EMPTY_VALUE}</TableCell>
                <TableCell>
                  <IconButton
                      color="primary"
                      onClick={() => handleClickOpen(transaction.settlementId ?? '', transaction.txId ?? '')}
                  >
                    <InfoIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
        <DialogTitle id="dialog-title">Transaction Details</DialogTitle>
        <DialogContent>
          {currentTxId && <div>Transaction ID: {currentTxId}</div>}
          {currentSettlementId && <div>Settlement ID: {currentSettlementId}</div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}
