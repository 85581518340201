import * as React from "react";
import Stack from "@mui/material/Stack";
import TokenInputSlider from "../components/TokenInputSlider";
import BalancesTable from "../components/BalancesTable";
import { useGetOwnBalanceQuery, useSendWnokMutation } from "../store/bankApi.gen";
import { LoadingButton } from "@mui/lab";
import SelectBankDropdownMenu from "../components/SelectBankDropdownMenu";
import { Grid } from "@mui/material";
import { createAlerts } from "../utils/helpers";

const TransferPage = () => {
  const [sendWnok, { isLoading }] = useSendWnokMutation();
  const { data: ownBalance, refetch: refetchOwnBalance } =
    useGetOwnBalanceQuery();

  const [targetAccountNo, setTargetAccountNo] = React.useState<string>("");
  const [targetAmount, setTargetAmount] = React.useState<number>(300);
  const [alertMsgs, setAlertMsgs] = React.useState<string[]>([]);
  const [severities, setSeverities] = React.useState<
    ("error" | "warning" | "info" | "success")[]
  >([]);
  let alerts = createAlerts(alertMsgs, severities);

  const handleTransferClick = () => {
    if (!targetAccountNo) {
      setAlertMsgs([...alertMsgs, "Please select a target account."]);
      setSeverities([...severities, "warning"]);
    } else if (!targetAmount) {
      setAlertMsgs([...alertMsgs, "Amount must be positive."]);
      setSeverities([...severities, "warning"]);
    } else {
      refetchOwnBalance();
      if (!ownBalance) {
        setAlertMsgs([...alertMsgs, "Error fetching own balance."]);
        setSeverities([...severities, "error"]);
      } else if (ownBalance.amount < targetAmount) {
        setAlertMsgs([
          ...alertMsgs,
          "Own balance (" +
            ownBalance.amount +
            ") not large enough to transfer " +
            targetAmount +
            " wNOK.",
        ]);
        setSeverities([...severities, "warning"]);
      } else {
        console.log(
          "TransferClick event with: " + targetAmount + " " + targetAccountNo,
        );
        sendWnok({
          tokenBalance: {
            amount: targetAmount,
            accountNo: targetAccountNo,
          },
        }).then((res) => handleComplete(res));
      }
    }
  };

  const handleComplete = (res: any) => {
    refetchOwnBalance();
    if (res.error) {
      console.log("Error during transfer: " + res.error.data);
      setAlertMsgs([...alertMsgs, "Error during transfer: " + res.error.data]);
      setSeverities([...severities, "error"]);
    } else {
      setAlertMsgs([...alertMsgs, "Successfully transferred amount"]);
      setSeverities([...severities, "success"]);
    }
  };

  const handleAmountChange = (
    event: React.ChangeEvent<HTMLInputElement> | Event,
    newValue?: number | number[],
  ) => {
    if (typeof newValue === "number") {
      setTargetAmount(newValue);
    } else if (event.target) {
      setTargetAmount(Number((event.target as HTMLInputElement).value));
    }
  };

  return (
    <div className="content-area">
      {alerts}

      <h2>Current balance</h2>
      {BalancesTable()}
      <h2>Actions</h2>
      <Stack direction="column">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <TokenInputSlider
              value={targetAmount}
              onChange={handleAmountChange}
            />
          </Grid>
          <Grid item>
            <SelectBankDropdownMenu
              onSelect={setTargetAccountNo}
              bankNameType="utxoName"
              placeholder="Select a recipient"
            />
          </Grid>
          <Grid item>
            <LoadingButton
              loading={isLoading}
              variant="text"
              onClick={handleTransferClick}
              style={{ height: 40 }}
            >
              Transfer
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default TransferPage;
