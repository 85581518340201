import * as React from "react";
import Stack from "@mui/material/Stack";
import TokenInputSlider from "../components/TokenInputSlider";
import BasicTable from "../components/BalancesTable";
import BalancesTableExternalNetwork from "../components/BalancesTableExternalNetwork";
import {
  useGetOwnBalanceQuery,
  useEscrowWnokMutation,
  useBridgeBackWnokMutation,
  useGetWholesaleBankDataQuery,
  useGetAccountBalanceMutation,
} from "../store/bankApi.gen";
import { LoadingButton } from "@mui/lab";
import SelectNetworkDropdownMenu from "../components/SelectNetworkDropdownMenu";
import { Grid } from "@mui/material";
import { createAlerts } from "../utils/helpers";
import { ownUtxoAccount } from "../components/Environment";

type bridgeOutBeneficiaryRecipientAndNetwork = {
  recipient: string;
  network: string;
};

type bridgeBackOriginatorRecipientAndNetwork = {
  recipient: string;
  network: string;
};

const BridgePage = () => {
  const [
    bridgeOutBeneficiaryRecipientAndNetwork,
    setBridgeOutBeneficiaryRecipientAndNetwork,
  ] = React.useState<bridgeOutBeneficiaryRecipientAndNetwork>({
    recipient: "",
    network: "",
  });
  const [
    bridgeBackOriginatorRecipientAndNetwork,
    setBridgeBackOriginatorRecipientAndNetwork,
  ] = React.useState<bridgeBackOriginatorRecipientAndNetwork>({
    recipient: "",
    network: "",
  });
  const [bridgeOutAmount, setBridgeOutAmount] = React.useState<number>(100);
  const [bridgeBackAmount, setBridgeBackAmount] = React.useState<number>(100);
  const { data: bankData } = useGetWholesaleBankDataQuery();
  const [alertMsgs, setAlertMsgs] = React.useState<string[]>([]);
  const [severities, setSeverities] = React.useState<
    ("error" | "warning" | "info" | "success")[]
  >([]);
  let alerts = createAlerts(alertMsgs, severities);

  const [bridgeOut, { isLoading: isLoadingBridgeOut }] =
    useEscrowWnokMutation();
  const [bridgeBack, { isLoading: isLoadingBridgeBack }] =
    useBridgeBackWnokMutation();
  const { data: ownBalance, refetch: refetchOwnBalance } =
    useGetOwnBalanceQuery();
  const [getAccountBalance, { data: accountBalance }] =
    useGetAccountBalanceMutation();

  const accountNo = ownBalance?.accountNo;
  const besuAddress = bankData?.find(
    (bank) => bank.utxoName === accountNo,
  )?.besuAddress;

  React.useEffect(() => {
    const fetchAccountBalance = () => {
      if (besuAddress) {
        getAccountBalance({
          getAccountBalanceRequest: {
            account: besuAddress,
            networkId: bridgeBackOriginatorRecipientAndNetwork.network,
          },
        });
      } else {
        console.log("Besu Address is not available yet.");
      }
    };

    fetchAccountBalance();

  }, [getAccountBalance, besuAddress, bridgeBackOriginatorRecipientAndNetwork.network]);

  const handleBridgeOutAmountChangeIssue = (
    event: React.ChangeEvent<HTMLInputElement> | Event,
    newValue?: number | number[],
  ) => {
    if (typeof newValue === "number") {
      setBridgeOutAmount(newValue);
    } else if (event.target) {
      setBridgeOutAmount(Number((event.target as HTMLInputElement).value));
    }
  };

  const handleBridgeBackAmountChangeIssue = (
    event: React.ChangeEvent<HTMLInputElement> | Event,
    newValue?: number | number[],
  ) => {
    if (typeof newValue === "number") {
      setBridgeBackAmount(newValue);
    } else if (event.target) {
      setBridgeBackAmount(Number((event.target as HTMLInputElement).value));
    }
  };

  const handleBridgeBackClick = () => {
    if (!bridgeBackAmount || bridgeBackAmount <= 0) {
      setAlertMsgs([...alertMsgs, "Amount to bridge back must be positive."]);
      setSeverities([...severities, "warning"]);
    } else if (
      !bridgeBackOriginatorRecipientAndNetwork ||
      !bridgeBackOriginatorRecipientAndNetwork.recipient ||
      !bridgeBackOriginatorRecipientAndNetwork.network
    ) {
      setAlertMsgs([...alertMsgs, "Please provide a recipient."]);
      setSeverities([...severities, "warning"]);
    } else if (!ownUtxoAccount) {
      setAlertMsgs([...alertMsgs, "ownUtxoAccount is not available yet."]);
      setSeverities([...severities, "warning"]);
    } else if (!accountBalance) {
      setAlertMsgs([...alertMsgs, "Selected balance type is not available."]);
      setSeverities([...severities, "warning"]);
    } else if (accountBalance.amount < bridgeBackAmount) {
      setAlertMsgs([
        ...alertMsgs,
        `External ledger balance (${accountBalance.amount}) not large enough to bridge back ${bridgeBackAmount} wNOK.`,
      ]);
      setSeverities([...severities, "warning"]);
    } else {
      console.log(
          "handleBridgeBackClick event with: " +
          bridgeBackAmount +
          " " +
          bridgeBackOriginatorRecipientAndNetwork.recipient +
          " @ " +
          bridgeBackOriginatorRecipientAndNetwork.network,
      );
      bridgeBack({
        bridgeBackRequest: {
          amount: bridgeBackAmount,
          remoteRecipient: ownUtxoAccount,
          remoteNetwork: "fabric",
          originatorAccount:
          bridgeBackOriginatorRecipientAndNetwork.recipient,
          originatorNetwork: bridgeBackOriginatorRecipientAndNetwork.network,
        },
      }).then((res) => handleCompleteBridging(res));
    }
  };

  const handleBridgeOutClick = () => {
    if (!bridgeOutAmount || bridgeOutAmount <= 0) {
      setAlertMsgs([...alertMsgs, "Amount to bridge out must be positive."]);
      setSeverities([...severities, "warning"]);
    } else if (
      !bridgeOutBeneficiaryRecipientAndNetwork ||
      !bridgeOutBeneficiaryRecipientAndNetwork.recipient ||
      !bridgeOutBeneficiaryRecipientAndNetwork.network
    ) {
      setAlertMsgs([...alertMsgs, "Please provide a recipient."]);
      setSeverities([...severities, "warning"]);
    } else {
      refetchOwnBalance();
      if (!ownBalance) {
        setAlertMsgs([...alertMsgs, "Error fetching own balance."]);
        setSeverities([...severities, "error"]);
      } else if (ownBalance.amount < bridgeOutAmount) {
        setAlertMsgs([
          ...alertMsgs,
          "Own balance (" +
            ownBalance.amount +
            ") not large enough to bridge out " +
            bridgeOutAmount +
            " wNOK.",
        ]);
        setSeverities([...severities, "warning"]);
      } else {
        console.log(
          "handleBridgeOutClick event with: " +
            bridgeOutAmount +
            " " +
            bridgeOutBeneficiaryRecipientAndNetwork.recipient +
            " @ " +
            bridgeOutBeneficiaryRecipientAndNetwork.network,
        );

        bridgeOut({
          escrowRequest: {
            amount: bridgeOutAmount,
            remoteRecipient: bridgeOutBeneficiaryRecipientAndNetwork.recipient,
            remoteNetwork: bridgeOutBeneficiaryRecipientAndNetwork.network,
          },
        }).then((res) => handleCompleteBridging(res));
      }
    }
  };

  const handleCompleteBridging = (res: any) => {
    if (res.error) {
      console.log("Error during bridging: " + res.error.data);
      setAlertMsgs([...alertMsgs, "Error during bridging: " + res.error.data]);
      setSeverities([...severities, "error"]);
    } else {
      setAlertMsgs([...alertMsgs, "Successful bridging"]);
      setSeverities([...severities, "success"]);
    }
  };

  return (
    <div className="content-area">
      {alerts}

      <h2>Core CBDC ledger balance</h2>
      {BasicTable()}

      <h2>Current balance external ledger</h2>
      {BalancesTableExternalNetwork()}

      <h2>Bridge Out Tokens</h2>
      <Stack direction="column">
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <SelectNetworkDropdownMenu
              onSelect={setBridgeOutBeneficiaryRecipientAndNetwork}
              placeholder="Select beneficiary"
            />
          </Grid>
          <Grid item xs>
            <TokenInputSlider
              value={bridgeOutAmount}
              onChange={handleBridgeOutAmountChangeIssue}
            />
          </Grid>
          <Grid item>
            <LoadingButton
              loading={isLoadingBridgeOut}
              variant="text"
              onClick={handleBridgeOutClick}
              style={{ height: 40, width: 110 }}
            >
              Bridge Out
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>

      <h2>Bridge Back Tokens</h2>
      <Stack direction="column">
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <SelectNetworkDropdownMenu
              onSelect={setBridgeBackOriginatorRecipientAndNetwork}
              placeholder="Select originator"
            />
          </Grid>
          <Grid item xs>
            <TokenInputSlider
              value={bridgeBackAmount}
              onChange={handleBridgeBackAmountChangeIssue}
            />
          </Grid>
          <Grid item>
            <LoadingButton
              loading={isLoadingBridgeBack} //TODO: implement
              variant="text"
              onClick={handleBridgeBackClick}
              style={{ height: 40, width: 110 }}
            >
              Bridge Back
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default BridgePage;
