import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { keyCloakSecured } from "../components/Environment";
import * as kc from "../services/Keycloak";

/**
 * Create a base API for endpoint injection by rtk-query-codegen-openapi
 */
export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: async (headers) => {
      headers.set("Content-Type", "application/json");
      if (keyCloakSecured) {
        let token = await kc.GetAccessToken();
        headers.set("Authorization", "Bearer " + token);
      }
    },
  }),
  endpoints: () => ({}),
});
