import { configureStore } from "@reduxjs/toolkit";
import { bankApi } from "./store/bankApi.gen";
import globalReducer from "./reducers/globalSlice";

export const store = configureStore({
  reducer: {
    [bankApi.reducerPath]: bankApi.reducer,
    global: globalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([bankApi.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
