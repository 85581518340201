import * as React from "react";
import { Link, useLocation } from "react-router-dom";
// material UI
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import * as kc from "../services/Keycloak";
import { cbEnabled } from "./Environment";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";

function Logout() {
  kc.Logout();
}

export default function NavBar() {
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="TopMenuTabs"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Dashboard" value="/" to="/" component={Link} />
            <Tab
              label="Transfer"
              value="/transfer"
              to="/transfer"
              component={Link}
            />
            <Tab
              label="Documents"
              value="/documents"
              to="/documents"
              component={Link}
            />
            <Tab label="Banks" value="/banks" to="/banks" component={Link} />
            <Tab label="Bridge" value="/bridge" to="/bridge" component={Link} />
            {cbEnabled && (
              <Tab
                label="Mint & Burn"
                value="/mint-burn"
                to="/mint-burn"
                component={Link}
              />
            )}
            {cbEnabled && (
              <Tab
                label="Oversight"
                value="/oversight"
                to="/oversight"
                component={Link}
              />
            )}
          </Tabs>
          <Stack direction="row" spacing={0}>
            <IconButton
              onClick={() => {
                window.location.href = "/info";
              }}
            >
              <InfoIcon />
            </IconButton>
            <Button
              variant="text"
              onClick={() => {
                Logout();
              }}
            >
              Logout
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
