import "./App.css";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/NavBar";
import DashboardPage from "./pages/DashboardPage";
import MintBurnPage from "./pages/MintBurnPage";
import TransferPage from "./pages/TransferPage";
import DocumentsPage from "./pages/DocumentsPage";
import OversightPage from "./pages/OversightPage";
import BanksPage from "./pages/BanksPage";
import BridgePage from "./pages/BridgePage";
import NoPage from "./pages/NoPage";
import { Divider, Box } from "@mui/material";
import React from "react";
import { useGetEventsQuery } from "./store/bankApi";
import {
  cbEnabled,
  orgLogoPath,
  orgIconPath,
  tabName,
} from "./components/Environment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/nb";
import { createAlerts } from "./utils/helpers";
import InfoPage from "./pages/InfoPage";

const locale = "nb";
dayjs.locale(locale);

function App() {
  const { data: events } = useGetEventsQuery(undefined, {
    pollingInterval: 7000,
  });
  const [alertMsgs, setAlertMsgs] = React.useState<string[]>([]);
  const [severities, setSeverities] = React.useState<
    ("error" | "warning" | "info" | "success")[]
  >([]);
  let alerts = createAlerts(alertMsgs, severities);

  React.useEffect(() => {
    document.title = tabName || "React App";
    const favicon = orgIconPath;
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (link) {
      link.href = `/${favicon}`;
    } else {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = `/${favicon}`;
      document.getElementsByTagName("head")[0].appendChild(newLink);
    }
  });

  React.useEffect(() => {
    if (events && events.length > 0) {
      console.log("Retrieved events: " + events);
      for (let event of events) {
        let message = "An unknown event occurred.";
        if (event === "CreatePrivateDocument") {
          message = "New document received.";
        }

        setAlertMsgs([...alertMsgs, message]);
        setSeverities([...severities, "info"]);
        console.log(event);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      <div className="draw-area">
        {alerts}

        <Box
          sx={{
            m: 2,
            height: 60,
            width: 400,
            position: "relative",
            overflow: "hidden",
          }}
        >
          <img
            src={orgLogoPath}
            alt="Organization Logo"
            style={{ height: "100%", width: "auto", maxWidth: "100%" }}
          />
        </Box>
        <Navbar />
        <div>
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            {cbEnabled && (
              <Route path="/mint-burn" element={<MintBurnPage />} />
            )}
            <Route path="/transfer" element={<TransferPage />} />
            <Route path="/documents" element={<DocumentsPage />} />
            <Route path="/banks" element={<BanksPage />} />
            <Route path="/bridge" element={<BridgePage />} />
            {cbEnabled && (
              <Route path="/oversight" element={<OversightPage />} />
            )}
            <Route path="/info" element={<InfoPage />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </div>
        <Divider sx={{ m: 5 }} />
      </div>
    </LocalizationProvider>
  );
}

export default App;
