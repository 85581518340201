//TODO: implement API call to retrieve networkProperties (CBDCHYP-596)
export const networkProperties = [
    {
        networkId: "appnok",
        label: "Besu AppNok",
    },
    {
        networkId: "rnok",
        label: "Besu Retail",
    }
];