import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOwnTransactionHistory: build.mutation<
      GetOwnTransactionHistoryApiResponse,
      GetOwnTransactionHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account-monitoring/getOwnTransactionHistory`,
        method: "POST",
        body: queryArg.dateInterval,
      }),
    }),
    getOwnBalance: build.query<GetOwnBalanceApiResponse, GetOwnBalanceApiArg>({
      query: () => ({ url: `/api/account-monitoring/getOwnBalance` }),
    }),
    getOwnBalanceAtDate: build.mutation<
      GetOwnBalanceAtDateApiResponse,
      GetOwnBalanceAtDateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account-monitoring/getOwnBalanceAtDate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getOwnBalanceHistory: build.mutation<
      GetOwnBalanceHistoryApiResponse,
      GetOwnBalanceHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account-monitoring/getOwnBalanceHistory`,
        method: "POST",
        body: queryArg.dateInterval,
      }),
    }),
    getOwnAssetRefs: build.query<
      GetOwnAssetRefsApiResponse,
      GetOwnAssetRefsApiArg
    >({
      query: () => ({ url: `/api/bridge/asset-ref/own` }),
    }),
    escrowWnok: build.mutation<EscrowWnokApiResponse, EscrowWnokApiArg>({
      query: (queryArg) => ({
        url: `/api/bridge/escrow-wnok`,
        method: "POST",
        body: queryArg.escrowRequest,
      }),
    }),
    getAccountBalance: build.mutation<
      GetAccountBalanceApiResponse,
      GetAccountBalanceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bridge/balance`,
        method: "POST",
        body: queryArg.getAccountBalanceRequest,
      }),
    }),
    bridgeOutWnok: build.mutation<
      BridgeOutWnokApiResponse,
      BridgeOutWnokApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bridge/out`,
        method: "POST",
        body: queryArg.bridgeOutRequest,
      }),
    }),
    bridgeBackWnok: build.mutation<
      BridgeBackWnokApiResponse,
      BridgeBackWnokApiArg
    >({
      query: (queryArg) => ({
        url: `/api/bridge/back`,
        method: "POST",
        body: queryArg.bridgeBackRequest,
      }),
    }),
    sendDocument: build.mutation<SendDocumentApiResponse, SendDocumentApiArg>({
      query: (queryArg) => ({
        url: `/api/document/sendDocument`,
        method: "POST",
        body: queryArg.documentTransferObject,
      }),
    }),
    getDocumentMetadataByTimeRange: build.mutation<
      GetDocumentMetadataByTimeRangeApiResponse,
      GetDocumentMetadataByTimeRangeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/document/getDocumentMetadataByTimeRange/${queryArg.accountNo}`,
        method: "POST",
        body: queryArg.dateInterval,
      }),
    }),
    getDocument: build.mutation<GetDocumentApiResponse, GetDocumentApiArg>({
      query: (queryArg) => ({
        url: `/api/document/getDocument`,
        method: "POST",
        body: queryArg.documentRequest,
      }),
    }),
    getZippedDocuments: build.mutation<
      GetZippedDocumentsApiResponse,
      GetZippedDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/document/getZippedDocuments`,
        method: "POST",
        params: { documents: queryArg.documents },
      }),
    }),
    getBalancesForAllParticipantsInNetwork: build.query<
      GetBalancesForAllParticipantsInNetworkApiResponse,
      GetBalancesForAllParticipantsInNetworkApiArg
    >({
      query: () => ({
        url: `/api/oversight/getBalancesForAllParticipantsInNetwork`,
      }),
    }),
    getTransactionHistoryForAllParticipantsInNetwork: build.mutation<
      GetTransactionHistoryForAllParticipantsInNetworkApiResponse,
      GetTransactionHistoryForAllParticipantsInNetworkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/oversight/getTransactionHistoryForAllParticipantsInNetwork`,
        method: "POST",
        body: queryArg.dateInterval,
      }),
    }),
    getTotalTransferVolumeHistory: build.mutation<
      GetTotalTransferVolumeHistoryApiResponse,
      GetTotalTransferVolumeHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/oversight/getTotalTransferVolumeHistory`,
        method: "POST",
        body: queryArg.dateInterval,
      }),
    }),
    getTotalWnokHistory: build.mutation<
      GetTotalWnokHistoryApiResponse,
      GetTotalWnokHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/oversight/getTotalWNOKHistory`,
        method: "POST",
        body: queryArg.dateInterval,
      }),
    }),
    getTbdBalances: build.query<
      GetTbdBalancesApiResponse,
      GetTbdBalancesApiArg
    >({
      query: () => ({ url: `/api/oversight/tbd/balance` }),
    }),
    getFabricStatus: build.query<
      GetFabricStatusApiResponse,
      GetFabricStatusApiArg
    >({
      query: () => ({ url: `/api/status/fabric` }),
    }),
    getBackendDbStatus: build.query<
      GetBackendDbStatusApiResponse,
      GetBackendDbStatusApiArg
    >({
      query: () => ({ url: `/api/status/backend-db` }),
    }),
    getBridgeStatus: build.query<
      GetBridgeStatusApiResponse,
      GetBridgeStatusApiArg
    >({
      query: () => ({ url: `/api/status/bridge` }),
    }),
    getBankUtxoNodeStatus: build.query<
      GetBankUtxoNodeStatusApiResponse,
      GetBankUtxoNodeStatusApiArg
    >({
      query: () => ({ url: `/api/status/utxo/bank/health` }),
    }),
    getBankUtxoNodeReadyStatus: build.query<
      GetBankUtxoNodeReadyStatusApiResponse,
      GetBankUtxoNodeReadyStatusApiArg
    >({
      query: () => ({ url: `/api/status/utxo/bank/ready` }),
    }),
    getAuditorUtxoNodeStatus: build.query<
      GetAuditorUtxoNodeStatusApiResponse,
      GetAuditorUtxoNodeStatusApiArg
    >({
      query: () => ({ url: `/api/status/utxo/auditor/health` }),
    }),
    getAuditorUtxoNodeReadyStatus: build.query<
      GetAuditorUtxoNodeReadyStatusApiResponse,
      GetAuditorUtxoNodeReadyStatusApiArg
    >({
      query: () => ({ url: `/api/status/utxo/auditor/ready` }),
    }),
    getIssuerUtxoNodeStatus: build.query<
      GetIssuerUtxoNodeStatusApiResponse,
      GetIssuerUtxoNodeStatusApiArg
    >({
      query: () => ({ url: `/api/status/utxo/issuer/health` }),
    }),
    getIssuerUtxoNodeReadyStatus: build.query<
      GetIssuerUtxoNodeReadyStatusApiResponse,
      GetIssuerUtxoNodeReadyStatusApiArg
    >({
      query: () => ({ url: `/api/status/utxo/issuer/ready` }),
    }),
    getTotalWnokInNetwork: build.query<
      GetTotalWnokInNetworkApiResponse,
      GetTotalWnokInNetworkApiArg
    >({
      query: () => ({ url: `/api/fabric/supply` }),
    }),
    getAllLegalEntitiesData: build.query<
      GetAllLegalEntitiesDataApiResponse,
      GetAllLegalEntitiesDataApiArg
    >({
      query: () => ({ url: `/api/legal-entity/all` }),
    }),
    getBankEntitiesData: build.query<
      GetBankEntitiesDataApiResponse,
      GetBankEntitiesDataApiArg
    >({
      query: () => ({ url: `/api/legal-entity/banks` }),
    }),
    getBridgeEntitiesData: build.query<
      GetBridgeEntitiesDataApiResponse,
      GetBridgeEntitiesDataApiArg
    >({
      query: () => ({ url: `/api/legal-entity/bridges` }),
    }),
    getBankNameMappingData: build.query<
      GetBankNameMappingDataApiResponse,
      GetBankNameMappingDataApiArg
    >({
      query: () => ({ url: `/api/legal-entity/bank-name-mapping/all` }),
    }),
    getBankNameMappingDataExcludingSelf: build.query<
      GetBankNameMappingDataExcludingSelfApiResponse,
      GetBankNameMappingDataExcludingSelfApiArg
    >({
      query: () => ({
        url: `/api/legal-entity/bank-name-mapping/excluding-self`,
      }),
    }),
    getOwnBankNameMappingData: build.query<
      GetOwnBankNameMappingDataApiResponse,
      GetOwnBankNameMappingDataApiArg
    >({
      query: () => ({ url: `/api/legal-entity/bank-name-mapping/self` }),
    }),
    sendWnok: build.mutation<SendWnokApiResponse, SendWnokApiArg>({
      query: (queryArg) => ({
        url: `/api/transaction/sendWNOK`,
        method: "POST",
        body: queryArg.tokenBalance,
      }),
    }),
    transferInternal: build.mutation<
      TransferInternalApiResponse,
      TransferInternalApiArg
    >({
      query: (queryArg) => ({
        url: `/api/tbd/transfer-internal`,
        method: "POST",
        body: queryArg.tbdTransferInternalRequest,
      }),
    }),
    transferExternal: build.mutation<
      TransferExternalApiResponse,
      TransferExternalApiArg
    >({
      query: (queryArg) => ({
        url: `/api/tbd/transfer-external`,
        method: "POST",
        body: queryArg.tbdTransferExternalRequest,
      }),
    }),
    mint: build.mutation<MintApiResponse, MintApiArg>({
      query: (queryArg) => ({
        url: `/api/tbd/mint`,
        method: "POST",
        body: queryArg.tbdMintRequest,
      }),
    }),
    burn: build.mutation<BurnApiResponse, BurnApiArg>({
      query: (queryArg) => ({
        url: `/api/tbd/burn`,
        method: "POST",
        body: queryArg.tbdBurnRequest,
      }),
    }),
    getAccounts: build.query<GetAccountsApiResponse, GetAccountsApiArg>({
      query: () => ({ url: `/api/tbd/accounts` }),
    }),
    getAccountsList: build.query<
      GetAccountsListApiResponse,
      GetAccountsListApiArg
    >({
      query: () => ({ url: `/api/tbd/accounts/list` }),
    }),
    createAccount: build.mutation<
      CreateAccountApiResponse,
      CreateAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/tbd/account`,
        method: "POST",
        body: queryArg.tbdAccountCreateRequest,
      }),
    }),
    getAccount: build.query<GetAccountApiResponse, GetAccountApiArg>({
      query: (queryArg) => ({ url: `/api/tbd/account/${queryArg.id}` }),
    }),
    deleteAccount: build.mutation<
      DeleteAccountApiResponse,
      DeleteAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/tbd/account/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getAccountHistory: build.query<
      GetAccountHistoryApiResponse,
      GetAccountHistoryApiArg
    >({
      query: (queryArg) => ({ url: `/api/tbd/account/${queryArg.id}/history` }),
    }),
    getAccountAtDate: build.mutation<
      GetAccountAtDateApiResponse,
      GetAccountAtDateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/tbd/account/${queryArg.id}/history`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getAccountHistories: build.query<
      GetAccountHistoriesApiResponse,
      GetAccountHistoriesApiArg
    >({
      query: () => ({ url: `/api/tbd/accounts/history` }),
    }),
    totalSupply: build.query<TotalSupplyApiResponse, TotalSupplyApiArg>({
      query: () => ({ url: `/api/tbd/supply` }),
    }),
    totalSupplyHistory: build.query<
      TotalSupplyHistoryApiResponse,
      TotalSupplyHistoryApiArg
    >({
      query: () => ({ url: `/api/tbd/supply/history` }),
    }),
    getStatusInfo: build.query<GetStatusInfoApiResponse, GetStatusInfoApiArg>({
      query: () => ({ url: `/api/tbd/status` }),
    }),
    mintWnok: build.mutation<MintWnokApiResponse, MintWnokApiArg>({
      query: (queryArg) => ({
        url: `/api/wnok-supply/mintWNOK`,
        method: "POST",
        body: queryArg.tokenBalance,
      }),
    }),
    burnWnok: build.mutation<BurnWnokApiResponse, BurnWnokApiArg>({
      query: (queryArg) => ({
        url: `/api/wnok-supply/burnWNOK`,
        method: "POST",
        body: queryArg.tokenBalance,
      }),
    }),
    getEvents: build.query<GetEventsApiResponse, GetEventsApiArg>({
      query: () => ({ url: `/api/event/getEvents` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bankApi };
export type GetOwnTransactionHistoryApiResponse =
  /** status 200 A list of transactions for the specified date interval */ TokenTransaction[];
export type GetOwnTransactionHistoryApiArg = {
  dateInterval: DateInterval;
};
export type GetOwnBalanceApiResponse =
  /** status 200 Account balance details */ TokenBalance;
export type GetOwnBalanceApiArg = void;
export type GetOwnBalanceAtDateApiResponse =
  /** status 200 Account balance at date */ TokenBalance;
export type GetOwnBalanceAtDateApiArg = {
  body: {
    /** ISO datetime */
    date: string;
  };
};
export type GetOwnBalanceHistoryApiResponse =
  /** status 200 A list of balances */ TokenBalance[];
export type GetOwnBalanceHistoryApiArg = {
  dateInterval: DateInterval;
};
export type GetOwnAssetRefsApiResponse =
  /** status 200 A list of asset references */ AssetRef[];
export type GetOwnAssetRefsApiArg = void;
export type EscrowWnokApiResponse =
  /** status 200 Default response */ VoidResponse;
export type EscrowWnokApiArg = {
  escrowRequest: EscrowRequest;
};
export type GetAccountBalanceApiResponse =
  /** status 200 Account balance for address */ TokenBalance;
export type GetAccountBalanceApiArg = {
  getAccountBalanceRequest: GetAccountBalanceRequest;
};
export type BridgeOutWnokApiResponse =
  /** status 200 Default response */ VoidResponse;
export type BridgeOutWnokApiArg = {
  bridgeOutRequest: BridgeOutRequest;
};
export type BridgeBackWnokApiResponse =
  /** status 200 Escrow request created successfully */ boolean;
export type BridgeBackWnokApiArg = {
  /** A purchase request body. */
  bridgeBackRequest: BridgeBackRequest;
};
export type SendDocumentApiResponse =
  /** status 200 Default response */ VoidResponse;
export type SendDocumentApiArg = {
  documentTransferObject: DocumentTransferObject;
};
export type GetDocumentMetadataByTimeRangeApiResponse =
  /** status 200 A list of documents and their metadata */ DocumentMetadata[];
export type GetDocumentMetadataByTimeRangeApiArg = {
  accountNo: AccountNo;
  dateInterval: DateInterval;
};
export type GetDocumentApiResponse =
  /** status 200 The document data */ Document;
export type GetDocumentApiArg = {
  documentRequest: DocumentRequest;
};
export type GetZippedDocumentsApiResponse =
  /** status 200 The documents data */ ZippedDocuments;
export type GetZippedDocumentsApiArg = {
  documents: string;
};
export type GetBalancesForAllParticipantsInNetworkApiResponse =
  /** status 200 Array of balances */ TokenBalance[];
export type GetBalancesForAllParticipantsInNetworkApiArg = void;
export type GetTransactionHistoryForAllParticipantsInNetworkApiResponse =
  /** status 200 Array of transactions */ TokenTransaction[];
export type GetTransactionHistoryForAllParticipantsInNetworkApiArg = {
  dateInterval: DateInterval;
};
export type GetTotalTransferVolumeHistoryApiResponse =
  /** status 200 Array of balances */ TokenBalance[];
export type GetTotalTransferVolumeHistoryApiArg = {
  dateInterval: DateInterval;
};
export type GetTotalWnokHistoryApiResponse =
  /** status 200 Array of balances */ TokenBalance[];
export type GetTotalWnokHistoryApiArg = {
  dateInterval: DateInterval;
};
export type GetTbdBalancesApiResponse =
  /** status 200 Array of balances */ TokenBalance[];
export type GetTbdBalancesApiArg = void;
export type GetFabricStatusApiResponse =
  /** status 200 Fabric network status */ FabricStatus;
export type GetFabricStatusApiArg = void;
export type GetBackendDbStatusApiResponse =
  /** status 200 Backend DB status */ BackendDbStatus;
export type GetBackendDbStatusApiArg = void;
export type GetBridgeStatusApiResponse =
  /** status 200 Bridge status */ BridgeStatus;
export type GetBridgeStatusApiArg = void;
export type GetBankUtxoNodeStatusApiResponse =
  /** status 200 Status */ UtxoNodeStatus;
export type GetBankUtxoNodeStatusApiArg = void;
export type GetBankUtxoNodeReadyStatusApiResponse =
  /** status 200 Status */ UtxoNodeStatus;
export type GetBankUtxoNodeReadyStatusApiArg = void;
export type GetAuditorUtxoNodeStatusApiResponse =
  /** status 200 Status */ UtxoNodeStatus;
export type GetAuditorUtxoNodeStatusApiArg = void;
export type GetAuditorUtxoNodeReadyStatusApiResponse =
  /** status 200 Status */ UtxoNodeStatus;
export type GetAuditorUtxoNodeReadyStatusApiArg = void;
export type GetIssuerUtxoNodeStatusApiResponse =
  /** status 200 Status */ UtxoNodeStatus;
export type GetIssuerUtxoNodeStatusApiArg = void;
export type GetIssuerUtxoNodeReadyStatusApiResponse =
  /** status 200 Status */ UtxoNodeStatus;
export type GetIssuerUtxoNodeReadyStatusApiArg = void;
export type GetTotalWnokInNetworkApiResponse =
  /** status 200 Total amount of wNOK */ TokenAmount;
export type GetTotalWnokInNetworkApiArg = void;
export type GetAllLegalEntitiesDataApiResponse =
  /** status 200 list with legal entity data */ LegalEntity[];
export type GetAllLegalEntitiesDataApiArg = void;
export type GetBankEntitiesDataApiResponse =
  /** status 200 list with legal entity data */ LegalEntity[];
export type GetBankEntitiesDataApiArg = void;
export type GetBridgeEntitiesDataApiResponse =
  /** status 200 list with legal entity data */ LegalEntity[];
export type GetBridgeEntitiesDataApiArg = void;
export type GetBankNameMappingDataApiResponse =
  /** status 200 list with legal entity name mapping data */ LegalEntityNameMapping[];
export type GetBankNameMappingDataApiArg = void;
export type GetBankNameMappingDataExcludingSelfApiResponse =
  /** status 200 list with legal entity name mapping data */ LegalEntityNameMapping[];
export type GetBankNameMappingDataExcludingSelfApiArg = void;
export type GetOwnBankNameMappingDataApiResponse =
  /** status 200 list with legal entity name mapping data */ LegalEntityNameMapping[];
export type GetOwnBankNameMappingDataApiArg = void;
export type SendWnokApiResponse =
  /** status 200 Default response */ VoidResponse;
export type SendWnokApiArg = {
  tokenBalance: TokenBalance;
};
export type TransferInternalApiResponse =
  /** status 200 Success response */ string;
export type TransferInternalApiArg = {
  tbdTransferInternalRequest: TbdTransferInternalRequest;
};
export type TransferExternalApiResponse =
  /** status 200 Default response */ VoidResponse;
export type TransferExternalApiArg = {
  tbdTransferExternalRequest: TbdTransferExternalRequest;
};
export type MintApiResponse = /** status 200 Success response */ string;
export type MintApiArg = {
  tbdMintRequest: TbdMintRequest;
};
export type BurnApiResponse = /** status 200 Success response */ string;
export type BurnApiArg = {
  tbdBurnRequest: TbdBurnRequest;
};
export type GetAccountsApiResponse =
  /** status 200 A list of accounts */ TbdAccount[];
export type GetAccountsApiArg = void;
export type GetAccountsListApiResponse =
  /** status 200 A list of account names */ string[];
export type GetAccountsListApiArg = void;
export type CreateAccountApiResponse =
  /** status 200 The created TBD account */ TbdAccount;
export type CreateAccountApiArg = {
  tbdAccountCreateRequest: TbdAccountCreateRequest;
};
export type GetAccountApiResponse = /** status 200 A TBD account */ TbdAccount;
export type GetAccountApiArg = {
  /** ID of the account to get */
  id: string;
};
export type DeleteAccountApiResponse =
  /** status 200 Default response */ VoidResponse;
export type DeleteAccountApiArg = {
  /** ID of the account to get */
  id: string;
};
export type GetAccountHistoryApiResponse =
  /** status 200 A TBD account history */ TbdAccountHistoryItem[];
export type GetAccountHistoryApiArg = {
  /** Account ID */
  id: string;
};
export type GetAccountAtDateApiResponse =
  /** status 200 The created TBD account */ TbdAccount;
export type GetAccountAtDateApiArg = {
  /** Account ID */
  id: string;
  body: string;
};
export type GetAccountHistoriesApiResponse =
  /** status 200 A TBD account history for all accounts */ TbdAccountHistory[];
export type GetAccountHistoriesApiArg = void;
export type TotalSupplyApiResponse =
  /** status 200 Total TBD supply amount */ number;
export type TotalSupplyApiArg = void;
export type TotalSupplyHistoryApiResponse =
  /** status 200 Total TBD supply history */ TbdTotalSupplyHistoryItem[];
export type TotalSupplyHistoryApiArg = void;
export type GetStatusInfoApiResponse =
  /** status 200 Status info object */ TbdStatusInfo;
export type GetStatusInfoApiArg = void;
export type MintWnokApiResponse =
  /** status 200 Default response */ VoidResponse;
export type MintWnokApiArg = {
  tokenBalance: TokenBalance;
};
export type BurnWnokApiResponse =
  /** status 200 Default response */ VoidResponse;
export type BurnWnokApiArg = {
  tokenBalance: TokenBalance;
};
export type GetEventsApiResponse = /** status 200 List of events */ Event[];
export type GetEventsApiArg = void;
export type TransactionType =
  | "CreateAccount"
  | "Mint"
  | "Burn"
  | "TransferwNOK"
  | "Escrow"
  | "ReleaseEscrow";
export type AccountNo = string;
export type TokenAmount = number;
export type TransactionId = string;
export type Timestamp = string;
export type TokenTransaction = {
  operation?: TransactionType;
  accountNoFrom?: AccountNo;
  accountNoTo?: AccountNo;
  amount?: TokenAmount;
  txId?: TransactionId;
  timestamp?: Timestamp;
  ownAccountNo?: AccountNo;
  ownBalance?: TokenAmount;
  /** Transaction Status */
  status?: "Confirmed";
};
export type InternalServerError = string;
export type DateInterval = {
  dateIntervalFrom: Timestamp;
  dateIntervalTo: Timestamp;
};
export type TokenBalance = {
  accountNo?: AccountNo;
  amount: TokenAmount;
  timestamp?: Timestamp;
};
export type TokenType = "wNOK" | "AssetRef";
export type RemoteNetworkName = string;
export type RemoteRecipientAccountNo = string;
export type AssetRef = {
  /** ID of the asset reference */
  assetRefId?: string;
  refwNOKAmount?: TokenAmount;
  tokenType?: TokenType;
  owner?: AccountNo;
  remoteNetwork?: RemoteNetworkName;
  remoteRecipient?: RemoteRecipientAccountNo;
};
export type VoidResponse = {
  /** Indicates if the operation was successful */
  success?: boolean;
};
export type EscrowRequest = {
  amount: TokenAmount;
  /** The identifier of the recipient on a remote system. */
  remoteNetwork: string;
  remoteRecipient: RemoteRecipientAccountNo;
};
export type GetAccountBalanceRequest = {
  /** The account address to retrieve the balance for */
  account: string;
  networkId: RemoteNetworkName;
};
export type BridgeOutRequest = {
  /** Reference ID of the asset to be bridged out */
  assetRefId: string;
  amount: TokenAmount;
  owner: AccountNo;
  remoteNetwork: RemoteNetworkName;
  remoteRecipient: RemoteRecipientAccountNo;
};
export type BridgeBackRequest = {
  amount: TokenAmount;
  remoteNetwork: RemoteNetworkName;
  remoteRecipient: RemoteRecipientAccountNo;
  originatorNetwork: RemoteNetworkName;
  originatorAccount: RemoteRecipientAccountNo;
};
export type DocumentTransferObject = {
  targetAccountNo?: AccountNo;
  /** Meta Data of the Document */
  documentMetadata?: string;
  /** Data of the document */
  documentData?: string;
};
export type DocumentMetadata = {
  /** document ID of the document */
  documentId?: string;
  /** Name of the document */
  documentName?: string;
  timestamp?: Timestamp;
  issuer?: AccountNo;
  sharedWith?: AccountNo;
  /** Filesize of the document in bytes */
  documentSize?: number;
};
export type Document = {
  /** document ID of the document */
  documentId?: string;
  /** Indicates if the document is encoded or not */
  dataIsEncoded?: boolean;
  /** Data of the document */
  data?: string;
};
export type DocumentId = string;
export type DocumentRequest = {
  /** The name of the issuer */
  issuer: string;
  sharedWithAccountNo: AccountNo;
  documentId: DocumentId;
};
export type ZippedDocuments = {
  /** zip file with documents */
  zippedDocuments?: string;
};
export type FabricStatus = {
  ownMspId?: string;
  isAlive: boolean;
  /** The literal response for display in case of negative alive status */
  aliveResponse?: string;
};
export type BackendDbStatus = {
  isAlive: boolean;
  aliveResponse?: string;
};
export type BridgeStatus = {
  isAlive: boolean;
  aliveResponse?: string;
};
export type UtxoNodeStatus = {
  isAlive?: boolean;
  aliveResponse?: string;
};
export type LegalEntityType = {
  /** Type of the legal entity */
  legalEntityType?: "bankType" | "bridgeType";
};
export type LegalEntity = {
  /** legal name of the entity */
  legalName?: string;
  /** Legal Entity Identifier */
  LEI?: string;
  accountNo?: AccountNo;
  /** Contact Person */
  contactPerson?: string;
  /** e-Mail address */
  eMail?: string;
  entityType?: LegalEntityType;
  /** The bank's URL */
  url?: string;
  /** Optional Fabric channel used by bank for TBD */
  tbdChannel?: string;
};
export type LegalEntityNameMapping = {
  /** legal name of the entity */
  legalName?: string;
  fabricName?: AccountNo;
  utxoName?: AccountNo;
  besuAddress?: AccountNo;
  entityType?: LegalEntityType;
};
export type TbdTransferInternalRequest = {
  /** Originator account identifier */
  originator?: string;
  /** Beneficiary account identifier */
  beneficiary?: string;
  /** Amount to transfer in NOK-equivalent TBD units */
  amount?: number;
};
export type TbdTransferExternalRequest = {
  /** Originator account identifier */
  originator?: string;
  /** Beneficiary account identifier */
  beneficiary?: string;
  /** Beneficiary bank identifier */
  beneficiaryBank?: string;
  /** Amount to transfer in NOK-equivalent TBD units */
  amount?: number;
};
export type TbdMintRequest = {
  /** Beneficiary of the newly minted TBD tokens */
  account?: string;
  /** Amount to mint in NOK-equivalent TBD units */
  amount?: number;
};
export type TbdBurnRequest = {
  /** Account from which the burned tokens are burned */
  account?: string;
  /** Amount to burn in NOK-equivalent TBD units */
  amount?: number;
};
export type TbdAccount = {
  /** Unique ID of the account */
  id: string;
  /** TBD balance of the account */
  balance: number;
};
export type TbdAccountCreateRequest = {
  /** The unique name of the new account */
  id: string;
};
export type NotFoundError = string;
export type TbdAccountHistoryItem = {
  /** Account id */
  id: string;
  /** TBD balance */
  balance: number;
  /** The transaction ID for which this record is the post-state */
  txId: string;
  timestamp: Timestamp;
};
export type TbdAccountHistory = {
  /** Account id */
  id?: string;
  history?: TbdAccountHistoryItem[];
};
export type TbdTotalSupplyHistoryItem = {
  /** Value of the total supply */
  totalSupply: number;
  /** The transaction ID for which this record is the post-state */
  txId: string;
  timestamp: Timestamp;
};
export type TbdStatusInfo = {
  alive: string;
  ownMspId: string;
};
export type Event = string;
export const {
  useGetOwnTransactionHistoryMutation,
  useGetOwnBalanceQuery,
  useGetOwnBalanceAtDateMutation,
  useGetOwnBalanceHistoryMutation,
  useGetOwnAssetRefsQuery,
  useEscrowWnokMutation,
  useGetAccountBalanceMutation,
  useBridgeOutWnokMutation,
  useBridgeBackWnokMutation,
  useSendDocumentMutation,
  useGetDocumentMetadataByTimeRangeMutation,
  useGetDocumentMutation,
  useGetZippedDocumentsMutation,
  useGetBalancesForAllParticipantsInNetworkQuery,
  useGetTransactionHistoryForAllParticipantsInNetworkMutation,
  useGetTotalTransferVolumeHistoryMutation,
  useGetTotalWnokHistoryMutation,
  useGetTbdBalancesQuery,
  useGetFabricStatusQuery,
  useGetBackendDbStatusQuery,
  useGetBridgeStatusQuery,
  useGetBankUtxoNodeStatusQuery,
  useGetBankUtxoNodeReadyStatusQuery,
  useGetAuditorUtxoNodeStatusQuery,
  useGetAuditorUtxoNodeReadyStatusQuery,
  useGetIssuerUtxoNodeStatusQuery,
  useGetIssuerUtxoNodeReadyStatusQuery,
  useGetTotalWnokInNetworkQuery,
  useGetAllLegalEntitiesDataQuery,
  useGetBankEntitiesDataQuery,
  useGetBridgeEntitiesDataQuery,
  useGetBankNameMappingDataQuery,
  useGetBankNameMappingDataExcludingSelfQuery,
  useGetOwnBankNameMappingDataQuery,
  useSendWnokMutation,
  useTransferInternalMutation,
  useTransferExternalMutation,
  useMintMutation,
  useBurnMutation,
  useGetAccountsQuery,
  useGetAccountsListQuery,
  useCreateAccountMutation,
  useGetAccountQuery,
  useDeleteAccountMutation,
  useGetAccountHistoryQuery,
  useGetAccountAtDateMutation,
  useGetAccountHistoriesQuery,
  useTotalSupplyQuery,
  useTotalSupplyHistoryQuery,
  useGetStatusInfoQuery,
  useMintWnokMutation,
  useBurnWnokMutation,
  useGetEventsQuery,
} = injectedRtkApi;
