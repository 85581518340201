import { TokenTransaction } from "../store/bankApi";
import { ownUtxoAccount } from "../components/Environment";
import {
  FormattedTransaction,
  isAmongTransactionTypes,
} from "../utils/helpers";

// Common formatting for transaction tables

/**
 * This string is displayed as a placeholder for undisplayable values
 */
export const EMPTY_VALUE = "--";

/**
 * Returns a string containing the user friendly operation name (if available),
 * and if applicable, the "Confirmed" status
 */
export function formattedOperation(transaction: FormattedTransaction): string {
  const operation =
    transaction.operationUserFriendlyName ?? transaction.operation;
  const status =
    transaction.status === "Confirmed" ? "" : ` (${transaction.status})`;
  return `${operation}${status}`;
}

/**
 * Returns the signed transaction amount or a placeholder.
 */
export function formattedAmount(transaction: TokenTransaction): string {
  if (!transaction.amount) {
    return EMPTY_VALUE;
  }
  if (transaction.operation === "Burn") {
    return String(-1 * transaction.amount);
  }
  if (
    isAmongTransactionTypes(
      ["Escrow", "TransferwNOK"],
      transaction.operation!,
    ) &&
    transaction.accountNoFrom === ownUtxoAccount
  ) {
    return String(-1 * transaction.amount);
  }
  return String(transaction.amount);
}
