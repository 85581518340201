//imports for accordion menu
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
//icon for accordion menu
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
//to customize style
import { styled } from "@mui/material/styles";

import {
  LegalEntity,
  useGetWholesaleBankDataQuery,
} from "../store/bankApi.gen";

//Custom Accordion Style
const Accordion = styled((props: AccordionProps) => (
  //text stays when box opens
  <MuiAccordion disableGutters {...props} />
))(({ theme }) => ({
  //solid line border
  border: `1px solid ${theme.palette.divider}`,
  // remove terminations between stacked accordions
  "&:not(:last-child)": { borderBottom: 0 },
  "&:before": { display: "none" },
}));

//Custom AccordionSummary Style
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  // define arrow icon and size
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  // place icon on left side
  flexDirection: "row-reverse",
  // rotate icon by 90 not 180 degerees
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  // margin between icon and text
  "& .MuiAccordionSummary-content": { marginLeft: theme.spacing(1) },
}));

function AccordionItem(data: LegalEntity) {
  return (
    <Accordion key={data.utxoName}>
      <AccordionSummary>
        <Typography align="left"> {data.legalName || data.utxoName} </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <table key={data.utxoName} style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>Name</td>
              <td align="right">{data.legalName}</td>
            </tr>
            <tr>
              <td>Contact person</td>
              <td align="right">{data.contactPerson}</td>
            </tr>
            <tr>
              <td>E-Mail</td>
              <td align="right">{data.eMail}</td>
            </tr>
            <tr>
              <td>LEI</td>
              <td align="right">{data.LEI}</td>
            </tr>
            <tr>
              <td>UTXO Name</td>
              <td align="right">{data.utxoName}</td>
            </tr>
            <tr>
              <td>Besu Address</td>
              <td align="right">{data.besuAddress}</td>
            </tr>
          </tbody>
        </table>
      </AccordionDetails>
    </Accordion>
  );
}

//function to build accordion menu
export default function BankAccordion() {
  const { data: banks } = useGetWholesaleBankDataQuery();
  if (!banks || banks.length === 0)
    return <>Loading</>;

  var fullAccordion: any[] = [];
  banks.forEach(function (bank) {
    fullAccordion.push(AccordionItem(bank));
  });

  return fullAccordion;
}
