import { ReactElement, ReactNode } from "react";
import { red, grey, green } from "@mui/material/colors";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListSubheader from "@mui/material/ListSubheader";
import Avatar from "@mui/material/Avatar";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  useGetBridgeStatusQuery,
  useGetBackendDbStatusQuery,
  useGetBankUtxoNodeStatusQuery,
  useGetFabricStatusQuery,
  useGetIssuerUtxoNodeStatusQuery,
  useGetAuditorUtxoNodeStatusQuery,
  FabricStatus,
  BackendDbStatus,
  BridgeStatus,
  UtxoNodeStatus,
} from "../store/bankApi.gen";

import { cbEnabled } from "./Environment";
import { skipToken } from "@reduxjs/toolkit/query";

/**
 * Define the intersection of all status DTOs (common fields)
 */
type AnyStatus =
  | FabricStatus
  | BackendDbStatus
  | BridgeStatus
  | UtxoNodeStatus
  | undefined;

function statusIcon(isAlive: boolean | undefined): ReactElement {
  switch (isAlive) {
    case true:
      // Positive response
      return <CheckCircleOutlineOutlinedIcon sx={{ color: green[500] }} />;
    case false:
      // Negative reponse
      // To be used when backend returns DTO with negative status
      return <CancelOutlinedIcon sx={{ color: red[500] }} />;
    default:
      // Undefined (no response)
      // To be used when bank backend is unavailable or returns HTTP/50x
      return <HelpOutlineOutlinedIcon sx={{ color: grey[500] }} />;
  }
}

/**
 * Create a StatusListItem based on a title and status.
 */
function StatusListItem(props: {
  title: string;
  status: AnyStatus;
}): ReactElement {
  function secondary(status: AnyStatus): ReactNode {
    let success: ReactElement = <>online</>;
    let formattedResponse: ReactElement = (
      <>{status?.aliveResponse && <p>Response: {status?.aliveResponse}</p>}</>
    );

    // Special handling for FabricStatus (show MSP ID when alive)
    if (status && (status as FabricStatus).ownMspId) {
      success = (
        <>
          online
          <br />
          {(status as FabricStatus).ownMspId}
        </>
      );
    }

    if (status && status.isAlive) return <>{success}</>;
    if (status && !status.isAlive)
      return (
        <>
          offline
          <br />
          {formattedResponse}
        </>
      );
    return <>querying...</>;
  }
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "background.paper", topmargin: 0 }}>
            {statusIcon(props.status?.isAlive)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={props.title}
          secondary={secondary(props.status)}
        />
      </ListItem>
    </>
  );
}

/**
 * Main component.
 *
 * Known limitation: When the bank backend goes offline, icons keep their last state.
 * They should instead re-render to undefined.
 */
export default function StatusIndicator() {
  const defaultParams = {
    pollingInterval: 10_000,
  };
  const { data: backendDbStatus } = useGetBackendDbStatusQuery(
    undefined,
    defaultParams,
  );
  const { data: fabricStatus } = useGetFabricStatusQuery(
    undefined,
    defaultParams,
  );
  const { data: bridgeStatus } = useGetBridgeStatusQuery(
    undefined,
    defaultParams,
  );
  const { data: auditorStatus } = useGetAuditorUtxoNodeStatusQuery(
    undefined,
    defaultParams,
  );
  const { data: bankStatus } = useGetBankUtxoNodeStatusQuery(
    undefined,
    defaultParams,
  );
  const { data: issuerStatus } = useGetIssuerUtxoNodeStatusQuery(
    cbEnabled ? undefined : skipToken,
    defaultParams,
  );

  return (
    <List
      dense={true}
      sx={{
        width: "100%",
        maxWidth: 180,
        bgcolor: "background.paper",
        boxShadow: 1,
        borderRadius: 1,
      }}
    >
      <ListSubheader component="div" id="nested-list-subheader">
        Services
      </ListSubheader>
      <StatusListItem title="Database" status={backendDbStatus} />
      <StatusListItem title="Fabric" status={fabricStatus} />
      <StatusListItem title="Bridge" status={bridgeStatus} />
      <ListSubheader component="div" id="nested-list-subheader">
        Token SDK Nodes
      </ListSubheader>
      <StatusListItem title="Auditor" status={auditorStatus} />
      <StatusListItem title="Bank" status={bankStatus} />
      {cbEnabled && <StatusListItem title="Issuer" status={issuerStatus} />}
    </List>
  );
}
