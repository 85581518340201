import React, {useEffect, useState} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  useGetOwnBalanceQuery,
  useGetWholesaleBankDataQuery,
  useGetAccountBalanceMutation,
} from "../store/bankApi.gen";
import { transformAccount } from "../utils/helpers";
import { networkProperties} from "../utils/networkProperties";

type Balances = Record<string, any>;

export default function BasicTable() {
  const { data: ownBalance } = useGetOwnBalanceQuery(undefined, {
    pollingInterval: 7000,
  });
  const { data: bankData } = useGetWholesaleBankDataQuery();

  const [getAccountBalance] = useGetAccountBalanceMutation();

  const [balances, setBalances] = useState<Balances>({});
  const accountNo = ownBalance?.accountNo;
  const besuAddress = bankData?.find((bank) => bank.utxoName === accountNo)?.besuAddress;

  useEffect(() => {
    if (besuAddress) {
      networkProperties.forEach(({ networkId }) => {
        const fetchBalance = () => {
          getAccountBalance({
            getAccountBalanceRequest: {
              account: besuAddress,
              networkId: networkId,
            },
          }).then(({ data }) => {
            setBalances((prevBalances: any) => ({ ...prevBalances, [networkId]: data?.amount}));
          });
        };

        fetchBalance();
        const intervalId = setInterval(fetchBalance, 7000);
        return () => clearInterval(intervalId);
      });
    }
  }, [getAccountBalance, besuAddress]);

  if (!ownBalance || !bankData) return <>Loading</>;

  const transformedAccount = transformAccount(ownBalance, bankData);
  if (!transformedAccount) return <>Loading</>;

  return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {networkProperties.map(({ networkId, label }) => (
                <TableRow key={networkId}>
                  <TableCell>{label}</TableCell>
                  <TableCell align="right">{balances[networkId]}</TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
}
