import React, { useState } from "react";
import Select, { SingleValue } from "react-select";
import { useGetOwnBankNameMappingDataQuery } from "../store/bankApi";

interface NamesListProps {
  onSelect: (remoteRecipientAndNetwork: {
    recipient: string;
    network: string;
  }) => void;
  placeholder: string;
}

type OptionType = {
  recipient: string;
  network: string;
  label: string;
};

const DropdownMenu: React.FC<NamesListProps> = ({ onSelect, placeholder }) => {
  const [selectedOption, setSelectedOption] =
    useState<SingleValue<OptionType>>(null);

  const { data: bankNameMapping, isLoading } = useGetOwnBankNameMappingDataQuery();

  if (isLoading || !bankNameMapping) return <>Loading...</>;

  const options: OptionType[] = bankNameMapping.map((bankName: any) => ({
    recipient: bankName["besuAddress"],
    network: "besu",
    label: `${bankName.legalName} @ Besu Retail`,
  }));

  const handleChange = (option: SingleValue<OptionType>) => {
    setSelectedOption(option);
    onSelect({
      recipient: option?.recipient ?? "",
      network: option?.network ?? "",
    });
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      styles={{
        container: (provided, state) => ({
          ...provided,
          width: 280,
          fontFamily: "Open Sans, sans-serif",
        }),
      }}
      placeholder={placeholder}
    />
  );
};

export default DropdownMenu;
